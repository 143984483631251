import { AuthService } from '../services/auth.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

// User must have a user id token (firebase) and api token (tokens ep) and saved user object (app)
// These are saved on login
// If they don't, they cannot access protected routes... they will be logged out instead of routed

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private route: Router, public authSvc: AuthService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    // Allow access to local login route
    if (state.url === '/login/local') {
      return true;
    }

    const uidToken = localStorage.getItem('uidToken');
    const user = localStorage.getItem('user');
    const version = this.authSvc.version;
    if (uidToken == null || (user == null)
      || (version !== localStorage.getItem('version'))) {
      this.authSvc.logout(true);
      return false;
    } else {
      return true;
    }
  }
}
