import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public error: string;
  public deviceInfo: DeviceInfo;
  public loading = false;

  constructor(private route: Router, private authSvc: AuthService,
    private deviceService: DeviceDetectorService,
    private activeRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    console.log(this.deviceInfo);
    this.loginRedirectCheck().then(() => {
    });
  }

  public isSupported(): boolean {

    if (this.deviceInfo.browser === 'MS-Edge-Chromium' && this.deviceInfo.os === 'Windows') {
      return true;
    }

    if (this.deviceInfo.browser === 'Safari' && this.deviceInfo.os === 'Mac' && !this.deviceService.isDesktop()) {
      return true;
    }

    if (this.deviceInfo.browser === 'Chrome') {
      return true;
    }

    return false;
  }

  private async loginRedirectCheck(): Promise<void> {
    if (!this.isSupported()) {
      return;
    }

    this.loading = true;

    // Check if we're in local development
    const isLocalDev = !window.location.hostname.includes('newmont.com');
    if (isLocalDev || environment.useFirebaseAuth) {
      this.route.navigate(['/login-fb']);
    } else {
      this.authWithSSO();
    }
  }

  private reset() {
    this.error = '';
  }

  // Fires on template button click
  public login() {
    this.loginRedirectCheck().then(() => {
    });
  }

  // Resets error status after user acknowledges error modal
  public closeErrorModal() {
    this.reset();
  }

  //SAML (default) option for Authentification
  private async authWithSSO() {
    const ssoValidationResponse = await this.authSvc.validateSSOProvider();
    if (!ssoValidationResponse.validated) {
      this.authSvc.signInRedirect().then(() => {
      });
      return;
    }
    if (ssoValidationResponse.intake) {
      //No Authorizaton needed
      this.redirectProcess();
    }
    else {
      //Make Authorization calls...
      const appValidationResponse = await this.authSvc.validateAuth(ssoValidationResponse);
      if (!appValidationResponse.validated) {
        this.error = appValidationResponse.error;
        this.loading = false;
        return;
      }
      this.redirectProcess();
    }
  }

  //RedirectURL Process
  redirectProcess(): void {
    this.activeRoute.queryParams.subscribe(async (params: Params) => {
      let redirectUrl: string = params.redirectUrl;
      if (redirectUrl && redirectUrl.includes('login')) {
        redirectUrl = undefined;
      }
      if (redirectUrl && params.search) {
        redirectUrl += `?${params.search.replace('_a_', '&')}`;
      }
      if (redirectUrl) {
        this.route.navigateByUrl(redirectUrl);
      } else {
        this.authSvc.navigateToPrimaryDomain();
      }
    });
  }
}
