import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { UpdateUser } from 'app/components/common/models/update-user.model';
import * as firebase from 'firebase/app';
import { Observable, Subject } from 'rxjs';
import { DomainsEnum } from '../components/common/models/domains.enum';
import { AppAuthResponseModel, FirebaseSSORedirectResponseModel } from '../components/common/models/firebase-sso-model';
import { SiteModel } from '../components/common/models/site.model';
import { UserAuthState, UserAuthStateImpersonate, UserAuthStateImpersonateComplete, UserAuthStateImpersonateRestore } from '../store';
import { UserProfileUpdate } from '../store/user.profile.state';
import { MetadataService, metadata } from './metadata.service';
import { PermissionService } from './permission.service';
import UserCredential = firebase.auth.UserCredential;
import Timeout = NodeJS.Timeout;
import { RedirectService } from './intake-redirect.service';

const moment = require('moment');

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authorization;
  errorMessageChange: Subject<string> = new Subject<string>();
  email;
  isLoading;
  isLoadingChange: Subject<boolean> = new Subject<boolean>();
  uid = null;
  user;
  userChange: Subject<object> = new Subject<object>();
  version = '3.0.1';
  private tokenInterval: Timeout;
  private isLocalDev = !window.location.hostname.includes('newmont.com');

  constructor(public afAuth: AngularFireAuth,
    public mdSvc: MetadataService,
    private route: Router,
    private ngZone: NgZone,
    private store: Store,
    private translate: TranslateService,
    private permissionService: PermissionService,
    private redirectService: RedirectService,
    private activeRoute: ActivatedRoute) {
    this.isLoading = true;
  }

  // Service fires on login component template button click
  // Loading set to true by default until metadata check passes

  // *** SUBSCRIPTIONS ***

  // Shared with main nav component to ensure load pre-template display
  changeIsLoading() {
    this.isLoading = false;
    this.isLoadingChange.next(this.isLoading);
  }

  // Shared with profile component to ensure user exists pre-component fetch
  dataIsLoading() {
    this.isLoading = true;
    this.isLoadingChange.next(this.isLoading);
  }

  // Subscribers: login, profile
  public get sharedLoadingStatus(): Observable<boolean> {
    return this.isLoadingChange.asObservable();
  }

  // Subscribers: login
  public get sharedErrorMessage(): Observable<string> {
    return this.errorMessageChange.asObservable();
  }

  // Subscribers: app (must have user before language data fetch)
  public get sharedUser(): Observable<object> {
    return this.userChange.asObservable();
  }

  // *** CHECK ***

  // Called after each service call to check if data is fetched
  // If true, route user to home
  isMetadataLoaded() {
    let loaded = false;
    if (metadata.months.length
      && metadata.riskCategories.length
      && !this.isUserStateFetched(metadata.userState)
      && !this.isAuthFetched(metadata.authState)
      && localStorage.getItem('user') !== null
      && localStorage.getItem('domains') !== null
      && this.mdSvc.isMetadataTagsLoaded()
    ) {
      loaded = true;
      this.changeIsLoading();
    }
    return loaded;
  }

  // Does a user object exist? (must be present to fetch language data)
  isUserStateFetched(userState) {
    return (userState && (Object.keys(userState).length === 0));
  }

  // Does an auth object exist? (must be present to fetch language data)
  isAuthFetched(authState) {
    return (authState && (Object.keys(authState).length === 0));
  }

  navigateToPrimaryDomain() {
    if (!this.authorization || !this.authorization.domains) {
      console.log('no auth data so need to login');
      this.navigate('/login');
      return;
    }
    const primary = this.authorization.domains.find(domain => domain.primary);
    if (primary && !location.pathname.includes(primary.domain.domain_name)) {
      console.log('Primary Domain: ', primary.domain.domain_name);
      this.navigate(`/${primary.domain.domain_id}`);
    }
  }

  public impersonateAs(email: string): void {
    this.store.dispatch(new UserAuthStateImpersonate(email));
    this.getUserMetadata(email).then(() => {
      localStorage.setItem('impersonating', 'true');
      this.store.dispatch(new UserAuthStateImpersonateComplete());
      this.navigateToPrimaryDomain();
    });
  }

  public stopImpersonate(): void {
    this.store.dispatch(new UserAuthStateImpersonate(undefined));
    this.getUserMetadata(this.email).then(() => {
      localStorage.setItem('impersonating', 'false');
      // localStorage.removeItem('impersonating');
      this.store.dispatch(new UserAuthStateImpersonateComplete());
      this.navigateToPrimaryDomain();
    });
  }

  public restoreImpersonate(): void {
    const impersonating = localStorage.getItem('impersonating');
    if (!impersonating || impersonating === 'false') {
      return;
    }
    const impState = this.store.selectSnapshot(UserAuthState.userAuth);
    if (impState.impId) {
      return;
    }
    this.user = JSON.parse(localStorage.getItem('user'));
    this.store.dispatch(new UserAuthStateImpersonateRestore(this.user.email));
  }

  public async signInRedirect(): Promise<void> {
    if (this.isLocalDev) {
      this.navigate('/login/local');
      return;
    }

    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/userinfo.email');
    provider.setCustomParameters({ hd: 'newmont.com' });

    await firebase.auth().signInWithRedirect(provider)
      .catch((error) => {
        console.log('Error: ', error);
        this.errorMessageChange.next(error.message);
        this.changeIsLoading();
      });
  }

  public async signInLocal(email: string, password: string): Promise<void> {
    try {
      const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
      const token = await userCredential.user.getIdToken(true);
      
      const ssoResponse = {
        version: this.version,
        validated: true,
        email: email,
        uidToken: token
      } as FirebaseSSORedirectResponseModel;

      await this.validateAuth(ssoResponse);
      this.navigateToPrimaryDomain();
    } catch (error) {
      console.log('Error: ', error);
      this.errorMessageChange.next(error.message);
      this.changeIsLoading();
    }
  }

  public signInFirebase(email: string, password: string): Observable<FirebaseSSORedirectResponseModel> {

    return new Observable<FirebaseSSORedirectResponseModel>(subscriber => {
      const response = { version: this.version, validated: false, email } as FirebaseSSORedirectResponseModel;

      firebase.auth().signInWithEmailAndPassword(email, password).then((userCredential: UserCredential) => {
        response.validated = true;
        userCredential.user.getIdToken(true).then((token) => {
          response.uidToken = token;
          subscriber.next(response);
          subscriber.complete();
        });
      })
        .catch(() => {
          subscriber.next(response);
          subscriber.complete();
        });
    });
  }


  public async validateSSOProvider(): Promise<FirebaseSSORedirectResponseModel> {
    const response = { version: this.version, validated: false } as FirebaseSSORedirectResponseModel;
    const redirectResponse: any = await firebase.auth().getRedirectResult();
    if (!redirectResponse || !redirectResponse.user) {
      return response;
    }
    response.email = redirectResponse.additionalUserInfo.profile.email;
    response.validated = true;
    response.uidToken = await firebase.auth().currentUser.getIdToken(true);

    this.activeRoute.queryParams.subscribe(async (params: Params) => {
      let redirectUrl: string = params.redirectUrl;
      if (redirectUrl && redirectUrl.includes('login')) {
        redirectUrl = undefined;
      }
      if (redirectUrl && params.search) {
        redirectUrl += `?${params.search.replace('_a_', '&')}`;
      }
      if (redirectUrl === "/intake") {
        localStorage.setItem('uidToken', response.uidToken);
        localStorage.setItem('version', response.version);
        const intake = {
          external_id: "",
          name_first: redirectResponse.additionalUserInfo.profile.given_name,
          name_last: redirectResponse.additionalUserInfo.profile.family_name,
          email: response.email,
          user_id: response.email,
          language_id: redirectResponse.additionalUserInfo.profile.locale
        } as UpdateUser;
        localStorage.setItem('intake', JSON.stringify(intake));
        response.intake = true;
      }
    });
    return response;
  }

  public getToken(): string {
    const token = localStorage.getItem('uidToken');
    if (!this.tokenInterval) {
      this.startTokenInterval();
    }
    this.refreshToken();
    return token;
  }

  public async refreshToken(): Promise<void> {
    const refreshDate = localStorage.getItem('refresh');
    if (!refreshDate) {
      localStorage.setItem('refresh', moment().toISOString());
      return;
    }
    const refreshTest = moment(refreshDate).toISOString();
    const diff = moment().diff(refreshTest, 'minutes');
    if (diff <= 40 || !firebase.auth().currentUser) {
      return;
    }
    const token = await firebase.auth().currentUser.getIdToken(true);
    console.log('new token:' + token);
    localStorage.setItem('uidToken', token);
    localStorage.setItem('refresh', moment().toISOString());
  }

  public async validateAuth(sso: FirebaseSSORedirectResponseModel): Promise<AppAuthResponseModel> {
    this.email = sso.email;
    this.uid = sso.uidToken;
    localStorage.setItem('uidToken', sso.uidToken);
    localStorage.setItem('version', sso.version);
    localStorage.setItem('loginTime', JSON.stringify(new Date()));
    this.startTokenInterval();
    await this.getMetadata(sso.email);

    const response = { validated: this.authorization !== undefined } as AppAuthResponseModel;

    if (!response.validated) {
      response.error = 'Not authorized';
    }
    return response;
  }

  private startTokenInterval(): void {
    const interval = 60000 * 20;
    if (this.tokenInterval) {
      clearInterval(this.tokenInterval);
    }
    console.log('starting token interval');
    this.tokenInterval = setInterval(() => {
      console.log('token interval');
      this.refreshToken();
    }, interval);

  }

  // *** LOG OUT ***

  // Sign out of firebase, clear LS, re-route to login
  public logout(addRedirect: boolean = false): void {
    this.logUserOut();
    const currentUrl = location.pathname;
    let search = location.search;
    if (search.length) {
      search = search.replace('?', '').replace('&', '_a_');
    }
    const url = addRedirect === true && currentUrl !== '/' ? `/login?redirectUrl=${encodeURI(currentUrl)}&search=${encodeURI(search)}` : '/login';
    this.route.navigateByUrl(url);

  }

  public noDomainAccess(domain: string): void {
    this.logUserOut();
    this.route.navigateByUrl(`/no-domain-access?domain=${domain}`);
  }

  public userLoggedOut(): void {
    this.logUserOut();
    this.route.navigateByUrl('/logged-out');
  }

  private logUserOut(): void {
    this.afAuth.auth.signOut();
    this.removeAllTokens();
  }

  // Explicity clear out LS
  removeAllTokens() {
    localStorage.removeItem('user');
    localStorage.removeItem('uidToken');
    localStorage.removeItem('version');
    localStorage.removeItem('domains');
    localStorage.removeItem('primaryDomain');
  }

  // *** HTTP ***
  public async initUser(): Promise<void> {
    this.mdSvc.validateTranslation();
    const currentUser = JSON.parse(localStorage.getItem('user'));
    await this.getAuthentication(currentUser.user_id);

    if (!this.isMetadataLoaded()) {
      if (this.route.url === '/') {
        this.navigateToPrimaryDomain();
      }
      await this.getMetadata(currentUser.user_id);
    }
  }

  public async initUserNoAuth(): Promise<void> {
    this.mdSvc.validateTranslation();
  }

  // Parent method, calls other service http methods
  private async getMetadata(email): Promise<void> {
    if (this.isMetadataLoaded()) {
      return;
    }
    this.getLocalMetaData();
    const user = await this.getCurrentUser(email);

    if (!user) {
      return;
    }

    await this.mdSvc.getDomains().toPromise();

    if (!this.authorization) {
      await this.getAuthentication(email);
    }
    this.isMetadataLoaded();
  }

  private async getUserMetadata(email: string): Promise<void> {
    await this.getCurrentUser(email);
    await this.getAuthentication(email, true);
  }

  // Fetch user authentication state by id
  public async getAuthentication(email, override?: boolean): Promise<void> {
    this.authorization = await this.mdSvc.getUserAuthorization(email, override).toPromise()
      .catch((error) => {
        this.authorization = undefined;
        console.log(error);
      });
    if (!this.authorization) {
      return;
    }
    // await this.mdSvc.getUserTableauJWT(email).toPromise();
    this.assembleDomainsList();
    this.permissionService.setPermissions(this.authorization);
  }

  public getUsersSites(domainId: DomainsEnum): SiteModel[] {

    if (!metadata.authState || !metadata.authState.domains) {
      return [];
    }

    const domain = metadata.authState.domains.find(d => d.domain.domain_id === domainId);
    if (!domain) {
      return [];
    }

    const sites = [];
    domain.sites.forEach(s => {
      sites.push(s.site);
    });
    return sites;
  }

  private assembleDomainsList(): void {
    const domainsList = metadata.authState.domains.map((domain) => {
      return domain.domain.domain_id;
    });
    metadata.domainsList = domainsList;
    localStorage.setItem('domains', JSON.stringify(domainsList));
    this.setPrimaryDomain();
  }

  private setPrimaryDomain(): void {
    let primaryDomain = metadata.authState.domains.find((domain) => {
      if (domain.primary) {
        return domain;
      }
    });

    if (!primaryDomain) {
      primaryDomain = metadata.authState.domains[0];
      primaryDomain.primary = true;
    }
    localStorage.setItem('primaryDomain', JSON.stringify(primaryDomain));
    this.isMetadataLoaded();
  }

  private async getLocalMetaData(): Promise<void> {
    await this.mdSvc.loadLocalMetadata();
  }

  public async getCurrentUser(email): Promise<any> {
    localStorage.removeItem('user');

    this.user = await this.mdSvc.getUser(email).toPromise()
      .catch(() => {
        return undefined;
      });

    if (!this.user) {
      return undefined;
    }
    localStorage.setItem('user', JSON.stringify(this.user));
    metadata.userState = this.user;
    this.store.dispatch(new UserProfileUpdate(this.user.language_id, this.user.profile_language_id));

    this.mdSvc.validateTranslation();
    this.translate.use(this.user.profile_language_id);

    this.userChange.next(this.user);
    return this.user;
  }

  public getLoggedInUserName(): string {

    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (!currentUser) {
      return '';
    }

    return `${currentUser.name_first} ${currentUser.name_last}`;
  }

  // *** ROUTING ***

  // Shared command to navigate to other routes
  public navigate(command): void {
    this.ngZone.run(() => this.route.navigate([command])).then();
  }
}
